<template>
  <div class="container pb-5">

    <h2 class="pt-4">Om Pyrodok</h2>

    <div class="py-2">
      Pyrodok-appen utvikles av Pyrodok AS.<br>
      Les gjerne mer på <a href="https://pyrodok.no/" target="_blank">nettsiden</a>.
    </div>

    <div class="py-2">
      App: {{ location }}<br>
      Koblet til: {{ this.app.api.server }}<br>
    </div>

    <div class="py-2">
      For å automatisk laste ned siste versjon må appen startes på nytt.<br>
      <b>Obs!</b> Du må da ha en internett-tilkobling.
    </div>

    <div class="py-2">
      <div id="version_latest">
        {{ this.app.latestVersion != '-' ? 'Nyeste versjon: ' + this.app.latestVersion : '' }}
      </div>
      <div id="version_current">
        Versjon installert: {{ this.app.version }} 
        <span class="installed" v-if="this.app.version === this.app.latestVersion">
          <i class="fas fa-check"></i>
        </span>
      </div>
    </div>

    <div class="py-2 text-center">
      <router-link class="btn btn-sm btn-primary py-2 px-3 me-3" to="/debug" replace><i class="fas fa-bug"></i>Debug</router-link>
      <button class="btn btn-sm btn-primary py-2 px-3" @click="restart()"><i class="fas fa-redo"></i>Restart app</button>
    </div>

  </div>
</template>

<style scoped>
.installed {
  color: green;
  padding-left: 10px;
}
</style>

<script>
export default {
  data() {
    return {
      location: window.location.host,
    }
  },

  methods: {
    restart() {
      console.log('about.restart');
      this.app.forceUpdateAndRestart();
    }
  }
}
</script>